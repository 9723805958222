import EllipsisLoading from "./EllipsisLoading";



export default function LoadingOverlay({ visible, text }) {
    if (!!visible)
        return <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backdropFilter: "blur(2px)" }} >
            <div>
                <div className="lds-ring" style={{ display: 'block' }}>
                    <div></div><div></div><div></div><div></div>
                </div>
                <div className="d-flex" style={{
                    position: "absolute",
                    width: 100,
                    height: 100,
                    color: "#44546f",
                    right: "calc(50% - 50px)",
                    top: "calc(50% + 25px)",
                    justifyContent: "center"
                }}>
                    <span>{text ?? ("Loading")}</span><EllipsisLoading />
                </div>
            </div>
        </div>

    return null
}