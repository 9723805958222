import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Trans, withTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import './AskToSubscribeModal.scss'
import './Modal.scss'
import { clearModals } from './ModalManager'

class AskToSubscribeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false }
    }

    acceptModal() {
        localStorage.setItem("ask-sub-modal-last-timestamp", JSON.stringify(new Date()))
        clearModals()
    }

    render() {
        const { t } = this.props;

        return (
            <Modal className="tw-modal ask-sub-modal" centered show={this.props.show} onHide={this.props.onClose} animation={false}>

                <div className="modal-icon">
                    <i className="fas fa-exclamation"></i>
                </div>
                <h5 className="modal-title mb-4" >{t("Your free trial is done.")}</h5>
                <p className="modal-text-para mb-3">
                    <Trans t={t}
                        defaults="<contrast>92% of your customers want fast support</contrast><br/>in their native language."
                        components={{ contrast: <span className="txt-contrast"></span> }} />
                </p>
                <p className="modal-text-para txt-bold-gray">
                    {t("Work smarter. Maximize customer satisfaction. 🏆")}
                </p>
                <div className="mt-4  d-flex">
                    {this.props.data.isAdmin && <Link className="mx-auto" to="/settings/subscription" onClick={this.acceptModal}>
                        <span className="confirm-text">{t("Continue", { ns: 'common' })}</span>
                    </Link>}
                    {!this.props.data.isAdmin && <a className="mx-auto" href="#" onClick={this.acceptModal}>
                        <span className="confirm-text">{t("Continue", { ns: 'common' })}</span>
                    </a>}
                </div>
            </Modal>
        );
    }
}

export default withTranslation("modal_sub_over")(AskToSubscribeModal);