// @refresh reset

import { useForm } from '@mantine/form';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, withReact } from 'slate-react';
import { useGetEmailAddressesQuery, useSendContactNoteMutation, useSendMessageEmailMutation } from '../../state/api';
import './ChatInputEmail.scss';
import { FooterSenderArea } from './ChatInputFooter';
import NotesArea, { NotesButtons } from './ChatInputNotes';
import EmailEditor, { EditorButtons, convertValueToHtml } from './EmailEditor';
import { addErrorToast } from './ToastManager';


let emptyInitialValue = [{ type: 'paragraph', children: [{ text: '' }] }]
function getInitialValue() { return ([{ type: 'paragraph', children: [{ text: '' }] }]) }

export default function ChatInputEmail({ activeChat }) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const { t } = useTranslation('chat')
    const [sendMessageEmail, { isLoading: isSendingEmail }] = useSendMessageEmailMutation()
    const [sendContactNote, { isLoading: isSendingNote }] = useSendContactNoteMutation()
    const { data: emailAddresses, isLoading: isLoadingEmailAddresses } = useGetEmailAddressesQuery()
    let editor = useMemo(() => withHistory(withReact(withImages(withLinks(createEditor())))), [])
    const [editorState, setEditorState] = useState([])
    const [state, setState] = useState('email')
    const form = useForm({
        initialValues: {
            subject: "",
            note: "",
            files: [],
            inlineFiles: [],
        },
    });

    useEffect(() => {
        let lastSubject = activeChat.messages.findLast(m => m.messageType === 'EMAIL')?.mailMessage?.subject || ""
        form.setFieldValue("subject", lastSubject?.startsWith("Re:") ? lastSubject : ("Re: " + lastSubject))
    }, [activeChat])

    const submitEmail = () => {
        let findInlineFiles = (elem) => {
            if (elem.type === 'image' && elem.file) {
                return elem.file
            }
            if (elem.children) {
                return elem.children.map(findInlineFiles).find(e => e)
            }
        }

        let content = convertValueToHtml(editorState)
        let fromAddressID = form.values.fromAddressID
        let recipient = activeChat?.contact?.email
        let contactID = activeChat.activeContactID
        let inReplyTo = activeChat.messages.findLast(m => m.messageType === 'EMAIL')?.externalID || ""
        let references = activeChat.messages.filter(m => m.messageType === 'EMAIL' && m.externalID).map(m => m.externalID) || []
        let inlineFiles = editorState.map(findInlineFiles).filter(e => e)

        if ((!editorState || editorState?.length <= 0) && form.values.files?.length <= 0) {
            addErrorToast(t("Could not send email"))
            return
        }

        if (!recipient) {
            addErrorToast(t("No recipient"))
            return
        }

        if (!fromAddressID) {
            addErrorToast(t("Sender address required"))
            return
        }

        sendMessageEmail({ subject: form.values['subject'], inReplyTo, content, fromAddressID, references, inlineFiles, files: form.values.files, recipient, contactID }).unwrap().then(() => {
            let lastSubject = form.values['subject']
            form.reset()
            form.setFieldValue("subject", ("Re: " + lastSubject))
            form.setFieldValue("fromAddressID", fromAddressID)

            let point = { path: [0, 0], offset: 0 }
            editor.selection = { anchor: point, focus: point };
            editor.history = { redos: [], undos: [] };
            editor.children = emptyInitialValue;
        }).catch(() => {
            addErrorToast(t("Could not send email"))
        }).finally(forceUpdate)
    }

    const canSendEmail = () => {
        return editorState.length > 0 && form.values.fromAddressID
    }

    const sendPrivateNote = () => {
        if (form.values.note)
            sendContactNote({ contactID: activeChat.activeContactID, message: form.values.note }).unwrap().then(() => {
                form.setFieldValue('note', '')
                setState('email')
            }).catch(() => {
                addErrorToast(t("Could not send note"))
            })
    }

    return (
        <Slate editor={editor} initialValue={getInitialValue()} onValueChange={value => { setEditorState(value) }}>
            <FooterSenderArea
                className='email-input-wrap'
                isSending={isSendingEmail || isSendingNote}
                state={state}
                notesEditor={{
                    editor: <NotesArea onChange={(e) => { form.setFieldValue('note', e.target.value) }} note={form.values['note']} submitNotes={sendPrivateNote} />,
                    buttons: <NotesButtons backClick={() => { setState('email') }} submitClick={sendPrivateNote} />
                }}
                emailEditor={{
                    buttons: <EditorButtons emailAddresses={emailAddresses} form={form} canSend={canSendEmail()} notesClick={() => { setState('note') }} submitClick={submitEmail} files={form.values.files} filesChanged={(files) => { form.setFieldValue('files', files) }} />,
                    editor: <EmailEditor emailAddresses={emailAddresses} form={form} editor={editor} />,
                }}
            />
        </Slate>
    )
}

const withImages = (editor) => {
    const { isVoid } = editor;

    editor.isVoid = (element) =>
        element.type === "image" ? true : isVoid(element);

    return editor;
};

const withLinks = (editor) => {
    const { isInline } = editor;

    editor.isInline = (element) =>
        element.type === "link" ? true : isInline(element);

    return editor;
};
